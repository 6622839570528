import getData from "@/__main__/get-data.mjs";
import LoLChampionProMatches from "@/game-lol/models/lol-probuild-match.mjs";
// import LoLProbuildSummary from "@/game-lol/models/lol-probuild-summary.mjs";
import * as API from "@/game-lol/utils/api.mjs";

const PER_PAGE = 20;

function fetchData(params) {
  const [championId, role = null] = params;

  Promise.all([
    getData(
      API.getChampionProMatches(championId, role, PER_PAGE),
      LoLChampionProMatches,
      ["lol", "championProMatches", championId, role],
      {
        shouldFetchIfPathExists: true,
      },
    ),
  ]);
}

export default fetchData;
